.accordion {
  display: flex;
  flex-direction: row;
  //width: 300px;
  width: 30%;
  height: 100%;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    height: auto;
  }
}

.tab {
  flex: 0 0 auto;
  width: 175px;
  //width: 100%;
  //height: 200px; /* Adjust the height as needed */
  height: 875px;
  //padding: 10px;
  background-color: #cc8383;
  //text-align: center;
  transition: width 0.3s, height 0.3s; /* Added height transition */
  cursor: pointer;
  overflow: hidden; /* Hide overflow content */
}

// .tab:last-child {
//   width: 75px;
// }

.tab.active {
  width: 550px;
  //width: 370px;
  height: 875px; /* Adjust the height as needed */
}
