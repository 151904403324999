.benefits-mobile-large-img {
  @media screen and (max-width: 600px) {
    width: 100% !important;
    height: auto !important;
  }
}

.benefits-mobile-bottom-img {
  @media screen and (max-width: 500px) {
    width: 170px !important;
    height: 235px !important;
  }
}
