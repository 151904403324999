@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.wrapper {
  display: flex;
  justify-content: center;
}

.plan-card {
  width: 400px;

  @include media-breakpoint-down(sm) {
    width: 300px !important;
  }
}
