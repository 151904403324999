// .arrow {
//   width: 36px;
//   height: 36px;
//   position: absolute;

//   border-radius: 50%;
//   padding: 10px;
//   top: 50%;
//   transform: translateY(-50%);
//   -webkit-transform: translateY(-50%);
//   fill: rgb(55, 45, 233);
//   cursor: pointer;
// }

// .arrow--left {
//   left: 0px;
// }
// .arrow--right {
//   left: auto;
//   right: 0px;
// }

// .arrow--disabled {
//   fill: opacity($color: #000000);
// }

.arrow {
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #625ea7;
  //background-color: #7152f3;
  border-radius: 50%;
  padding: 10px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  fill: rgb(241, 240, 248);
  cursor: pointer;
}

.arrow--left {
  left: 0px;
}
.arrow--right {
  left: auto;
  right: 0px;
}

.arrow--disabled {
  fill: opacity($color: #000000);
}
