@import "/src/app/styles/mixins.scss";
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.geography {
  fill: #ccc; /* Default fill color for geographies */
  stroke: #fff; /* Border color for geographies */
  stroke-width: 0.5;
  cursor: pointer;
}

.geography:hover {
  fill: #99c2ff; /* Change fill color on hover */
}

.info-box {
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
}

.fillColor {
  fill: #e5e9eb;
  &.sanjose {
    fill: #51279e;

    &:hover {
      fill: #734bbf !important;
    }
  }
  &.alajuela {
    fill: #ab0f39;

    &:hover {
      fill: #ab0f3990 !important;
    }
  }
  &.cartago {
    fill: #4498d0;

    &:hover {
      fill: #4498d090 !important;
    }
  }
  &.heredia {
    fill: #eab525;

    &:hover {
      fill: #e7da4d90 !important;
    }
  }
  &.limon {
    fill: #20a565;

    &:hover {
      fill: #20a56598 !important;
    }
  }
  &.puntarenas {
    fill: #ee881a;

    &:hover {
      fill: #ee881a90 !important;
    }
  }
  &.guanacaste {
    fill: #ad26c1;

    &:hover {
      fill: #ad26c190 !important;
    }
  }

  cursor: pointer;

  &:hover {
    fill: #625ea7 !important;
  }
}

#plansSection {
  @include belowSM {
    // Styles for mobile screens
    // padding: 0;
    // width: 365px;
    margin: 0 auto;
  }
}

.keen-slider {
  @include mobile {
    // Styles for mobile screens

    // width: 365px;
    margin: 2em auto;
  }
  // @include belowSM {
  //   // Styles for mobile screens

  //   width: 3650px;
  //   margin: 2em auto;
  // }
}

.termsList {
  padding: 0px 10px 0px 10px;

  li {
    font-family: "Mulish", sans-serif !important;
    font-weight: 500;
    margin-left: 0.5em;
    font-size: 0.9rem;

    padding-bottom: 0.6em;
    text-align: justify;
    list-style: none;
  }
  p {
    font-family: "Mulish", sans-serif !important;
    font-weight: 500;
    margin-left: 0.5em;
    font-size: 0.9rem;

    padding-bottom: 0.6em;
    text-align: justify;
    list-style: none;
  }
  a {
    margin-left: 5px;
    color: #7152f3;
  }
}

a {
  //text-decoration: underline !important;
  text-decoration: none;
  cursor: "pointer !important";
}
.faqtest {
  a {
    color: #f6a517 !important;
  }
}
