@import "../../styles/mixins.scss";

.keen-carousel {
  &.small {
    [class^="headerSlide"],
    [class*="headerSlide"] {
      display: flex;
      flex-direction: column;
      border-radius: 18px;
      max-height: 250px !important;
      // min-width:400px !important;
      // max-width: 400px !important;
      .image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 250px; /* Adjust the height as desired */
          width: 100%;

          object-fit: contain;
        }
      }
      .image-wrapper2 {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 250px; /* Adjust the height as desired */
          width: 100%;

          object-fit: contain;
        }
      }
    }
    [class^="largeSlide"],
    [class*="largeSlide"] {
      display: flex;
      flex-direction: column;
      border-radius: 18px;
      max-height: 250px !important;
      min-height: 250px !important;
      min-width: 800px !important;
      max-width: 800px !important;
      .image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        img {
          height: 250px; /* Adjust the height as desired */
          width: 100%;

          object-fit: contain;
        }
      }
    }
  }
  &.large {
    [class^="headerSlide"],
    [class*="headerSlide"] {
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
      //max-height:250px;
      max-height: 300px;

      .image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 300px; /* Adjust the height as desired */
          width: 100%;

          object-fit: contain;
        }
      }
    }
  }
}
// @include mobile() {
//   [class^="headerSlide"],
//   [class*="headerSlide"] {

//       img {
//           width: 150px;
//           height: auto;
//           margin: 0 auto;
//       }
//   }
// }
