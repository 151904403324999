.content {
  width: 400px;
  margin: auto;
  padding: 0 1.25rem;

  @media screen and (max-width: 600px) {
    width: 350px;
    position: relative;
    left: -50%;
    transform: translateX(60%);
  }

  @media screen and (max-width: 550px) {
    transform: translateX(62%);
  }

  @media screen and (max-width: 500px) {
    transform: translateX(55%);
  }

  @media screen and (max-width: 480px) {
    transform: translateX(48%);
  }

  @media screen and (max-width: 420px) {
    transform: translateX(43%);
  }

  @media screen and (max-width: 400px) {
    transform: translateX(40%);
  }

  @media screen and (max-width: 390px) {
    width: 300px;
    transform: translateX(45%);
  }

  @media screen and (max-width: 360px) {
    transform: translateX(42%);
  }
}

.inner-content {
  width: 400px;
  margin: auto;
  padding: 0 1.25rem;

  @media screen and (max-width: 599px) {
    width: 350px;
    position: relative;
    //left: -50%;
    //transform: translateX(55%);
  }

  @media screen and (max-width: 390px) {
    width: 300px;
    position: relative;
    //left: -50%;
    //transform: translateX(55%);
  }
}

.accordion {
  margin: 0;
  padding: 0;
  background: #f4f4f4;
  color: #00432f;
}

.accordion__item {
  // box-shadow: 0px 9px 9px -3px #000000;
  position: relative;
  list-style-type: none;
  padding: 2% 4%;
  // border-bottom-right-radius: 50px;
  // border-bottom-left-radius: 50px;
}

.check {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.accordion__title {
  padding-right: 1.5rem;
  font-size: 1.25rem;
}

.accordion__arrow {
  position: absolute;
  right: 0;
  margin-top: 26px;
  margin-right: 6%;
}

.accordion__arrow::before,
.accordion__arrow::after {
  content: "";
  position: absolute;
  background-color: #00432f;
  width: 3px;
  height: 9px;
  transition: transform 0.25s ease-in-out;
}

.accordion__arrow::before {
  transform: translateX(-2px) rotate(45deg);
}

.accordion__arrow::after {
  transform: translateX(2px) rotate(-45deg);
}

input[type="checkbox"]:checked ~ .accordion__arrow::before {
  transform: translateX(2px) rotate(45deg);
}

input[type="checkbox"]:checked ~ .accordion__arrow::after {
  transform: translateX(-2px) rotate(-45deg);
}

.accordion__content {
  position: relative;
  //max-height: 200px;
  min-height: 100%;
  margin: 0;
  opacity: 1;
  overflow: hidden;
  transition: all 0.35s ease-in-out;
  line-height: 1.6;
  z-index: 2;
}

input[type="checkbox"]:checked ~ .accordion__content {
  max-height: 0;
  opacity: 0;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
  }
}
