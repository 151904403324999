@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.search {
  position: relative;
}

.search input {
  height: 60px;
  text-indent: 25px;
  background-color: #1f2131;
  width: 50%;
  margin-left: auto;
  border: 1px solid rgba(114, 115, 124, 0.5);

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.search input:focus {
  box-shadow: none;
}

.search input::placeholder {
  color: #72737c;
}

.search .fa-search {
  position: absolute;
  top: 20px;
  left: 16px;
}

.search button {
  position: absolute;
  color: white;
  font-size: 12px;
  top: 5px;
  right: 20px;
  height: 50px;
  width: 110px;
}

.social-icons {
  position: relative;
  cursor: pointer;
  z-index: 40;
  color: white;
}

.social-icons::after {
  content: "";
  //background-color: #3CB5DB;
  background-color: transparent;
  border-radius: 50%;
  padding: 15px;
  position: absolute;
  display: block;
  top: -4%;
  left: -35%;
}

.social-icons:hover::after {
  background-color: #3cb5db;
}

.copyright {
  border-top: 1px solid rgba(114, 115, 124, 0.5);
}
