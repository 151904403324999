@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.header-container {
  width: 90% !important;

  @include media-breakpoint-down(xl) {
    width: 100% !important;
    padding: 8px 20px;
  }
}

.header-navbar {
  width: 278px;

  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}

.truncate-text {
  @media screen and (max-width: 1100px) {
    width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
