@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.cube {
  display: flex;
  position: relative;
  padding: 15px;

  img {
    margin-left: auto;
    position: absolute;
    width: 150px;
    height: 150px;
  }
}

.side-text {
  float: left;
}

.hero-app-example {
  width: 75%;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.hero-bg-img {
  background-image: url("../../assests/bg2.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  @include media-breakpoint-down(xl) {
    background-image: url("../../assests/bg2.png");
    background-size: cover, 100%;
  }
  @include media-breakpoint-down(sm) {
    background-image: url("../../assests/bg2.png");
    background-size: cover, 100%;
    // min-height: 100vh;
  }
}
