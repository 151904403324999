.backdrop-on-top {
  z-index: 1500;
}

.billing-dialog {
  max-width: 100% !important;
  width: 32% !important;
}

.billing-content {
  min-height: 95vh !important;
}

.selected-plan-dialog {
  max-width: 100% !important;
  width: 30% !important;

  @media screen and (max-width: 1200px) {
    width: 100% !important;
  }
}

.selected-plan-content {
  width: 100% !important;
  color: #fff !important;
}

.sign-in-dialog {
  max-width: 100% !important;
  width: 50% !important;
  height: 100% !important;
}

.sign-in-content {
  min-height: 70vh !important;
}
